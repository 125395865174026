import AddressDTO from "@/dto/AddressDTO";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import {BankAccountType_OLD} from "@/dto/taxation/individual/BankAccountType_OLD";
import {BankAccountType} from "@/dto/BankAccountType";
import {WorkspaceType} from "@/dto/auth/Workspace";
import {HolderType} from "@/dto/HolderType";

export default class BankAccountDTO {
    id: number | null = null;

    bankName: string | null = null;

    accountNumber: string | null = null;

    routingNumber: string | null = null;

    city: string | null = null;

    state: string | null = null;

    bankPhone: string | null = null;

    achForm: SignableDocumentDTO | null = null;

    companyId: number | null = null;

    personId: number | null = null;

    isMain: boolean | null = null;

    accountHolder: string | null = null;

    verified: boolean | null = null;

    accountType: BankAccountType | null = null

    holderType: HolderType | null = null;
}